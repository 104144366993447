import React, { useState } from "react";
import {
  Hint,
  XYPlot,
  LineSeries,
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  YAxis,
} from "react-vis";
import progress from "./progress.json";
import "../node_modules/react-vis/dist/style.css";
import "./App.css";
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    "& .MuiTypography-colorTextSecondary": {
      color: "rgba(255, 255, 255, 0.6) !important",
    },
  },
}));

function App() {
  const classes = useStyles();

  const [hoverValue, setHoverValue] = useState<null | any[]>(null);

  const data = progress.date.map((d, i) => ({
    x: d,
    y: progress.performance[i],
  }));

  const dateFormatter = (d: number, withYear: boolean = false) => {
    const date = new Date(d * 1000);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      (withYear ? "/" + date.getFullYear() : "")
    );
  };

  const width = window.innerWidth;

  return (
    <div className="App">
      <Typography variant="h2" component="h2">
        san.go.KAI
      </Typography>
      <Typography variant="h6" component="h6">
        Crypto Trading AI
      </Typography>
      <XYPlot height={width / 2} width={width}>
        <HorizontalGridLines
          tickTotal={10}
          style={{ strokeWidth: 1, stroke: "rgba(255, 255, 255, 0.15)" }}
        />
        <VerticalGridLines
          tickTotal={40}
          style={{ strokeWidth: 1, stroke: "rgba(255, 255, 255, 0.15)" }}
        />
        <LineSeries
          data={data}
          curve={"curveMonotoneX"}
          onNearestX={(value, { index }) => setHoverValue([value, index])}
        />
        <XAxis
          tickTotal={20}
          tickLabelAngle={-45}
          tickFormat={(v) => dateFormatter(v, false)}
        />
        <YAxis />
        {hoverValue && (
          <Hint
            format={(value) => [
              { title: "Progress", value: value.y + "%" },
              { title: "Date", value: dateFormatter(value.x, true) },
            ]}
            value={hoverValue[0]}
          />
        )}
      </XYPlot>
      <List className={classes.root}>
        <ListItem>
          <ListItemText primary="Started" secondary="Dec 17, 2019" />
        </ListItem>
        <ListItem>
          <ListItemText primary="ROI" secondary="442.31%" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Trades" secondary="356" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Active" secondary="0" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Long/Short" secondary="324/32" />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Pairs"
            secondary="USDC/BTC, ONT/BTC, MDT/BTC, ENJ/BTC, BOT/BTC, HBAR/BTC, ELF/BTC, SXP/BTC, THETA/BTC, QLC/BTC, BAT/BTC, CRV/BTC, DOT/BTC, ARK/BTC, FIO/BTC... (+12 more)"
          />
        </ListItem>
      </List>
    </div>
  );
}

export default App;
